/* main*/
.main {
  background: linear-gradient(
    360deg,
    #aca777 -3.47%,
    #a8534e 63.79%,
    #250bc6 118.52%
  );
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

/* styles.css */
/* Define a CSS class for your navbar */

/* Use a CSS variable to control the background color */
.navbar.color-black {
  --navbar-bg-color: black;
}

.navbar.color-blue {
  --navbar-bg-color: blue;
}
a {
  text-decoration: none;
}
/* header */
.navbar {
  /* background-color: var(--navbar-bg-color, #ffffff4d); */
  background: #02141e;
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  max-width: 950px;
  height: 40px;
  justify-content: space-between;
  padding: 2px 11px 2px 22px;
  border-radius: 65px;
  /* background: #ffffff4d; */
  z-index: 100;
}

.second-navbar {
  /* background-color: var(--navbar-bg-color, #ffffff4d); */
  background: #02141e;
  position: fixed;
  top: 25px;
  left: 10%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  max-width: 200px;
  height: 40px;
  justify-content: space-between;
  padding: 2px 11px 2px 22px;
  border-radius: 65px;
  /* background: #ffffff4d; */
  z-index: 100;
}

.nav-links {
  font-size: 14px;
  list-style: none;
  margin-top: 1px;
  display: flex;
}

.nav-links li {
  margin-right: 30px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.nav-links li a {
  text-decoration: none;
  color: #fff;
}

.nav-logo img {
  max-width: 200px;
  padding-right: 80px;
  margin-top: -9px;
}

.nav-links button {
  width: 70px;
  margin-left: 100px;
  margin-top: -6px;
  height: 25px;
  font-size: 12px;
  padding: inherit;
  border-radius: 100px;
  color: #fff;
  background: linear-gradient(180deg, #250e44 -73.33%, #703480 278.33%);
}

/*contents*/
/* About */
.about-page {
  padding: 20px;
  min-height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.about-us {
  position: absolute;
  top: 0%;
  font-size: 14px;
  text-transform: uppercase;
}
.about {
  position: absolute;
  top: 5%;
  font-size: 44px;
}
.about span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}
.about-box {
  position: relative;
  left: -40px;
}
.about-para {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 20px;
  padding: 50px;

  border-radius: 15px;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}
.about-image img {
  position: relative;
  top: 60px;
  right: 40px;
}
/* ProblemStatement.css */

.problem-container {
  padding: 20px;
  min-height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.problem-statement {
  position: absolute;
  top: 10%;
  font-size: 14px;
  text-transform: uppercase;
}
.problem {
  position: absolute;
  top: 15%;
  font-size: 44px;
}
.problem span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}
.problem-para {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 20px;
  padding: 50px;
  border-radius: 15px;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}
/* Solution */
.solution-page {
  padding: 20px;
  min-height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.solution-our {
  position: absolute;
  top: 5%;
  font-size: 14px;
  text-transform: uppercase;
}
.solution {
  position: absolute;
  top: 10%;
  font-size: 44px;
}
.solution span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}

.left-div {
  flex: 1;
}

.left-div img {
  position: relative;
  width: 30%;
  left: 30%;
  border-radius: 8px;
}

.right-div {
  flex: 1;
  position: relative;
  right: 3%;
}

.transparent-box {
  background: rgba(0, 0, 0, 0.3);
  padding: 20px 20px;
  color: #fff;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  display: flex;
  font-size: 20px;
}
.transparent-box li:before {
  content: "\2022";
  color: #fff;
  font-size: 44px;
  position: absolute;
  left: 5%;
}

.transparent-box h2 {
  margin-bottom: 10px;
}

.transparent-box ul {
  list-style-type: none;
  padding: 0;
}

.transparent-box li {
  margin-bottom: 8px;
  left: 15%;
}
/* Mission */
.Mission-page {
  padding: 20px;
  min-height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.Mission-us {
  position: absolute;
  top: 0%;
  font-size: 14px;
  text-transform: uppercase;
}
.Mission {
  position: absolute;
  top: 5%;
  font-size: 44px;
}
.Mission span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}

.Demo {
  position: absolute;
  top: -20%;
  font-size: 44px;
}
.Demo span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}

.Mission-para {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 20px;
  padding: 50px;
  border-radius: 15px;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

/* Services.css */
.services-section {
  padding: 20px;
  min-height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.slide {
  position: absolute;
  top: 13%;
  width: 1%;
  right: 30%;
}
/* .headings {
  position: absolute;
  top: 20px;
  left: 50px;
  color: white;
} */
.OurServices {
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 14px;
  text-transform: uppercase;
}
.OServices {
  position: absolute;
  top: 13%;
  left: 5%;
  font-size: 44px;
}

.OServices span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 50%;
  font-weight: bold;
}
.frame-container {
  position: relative;
  top: 30%;
  left: 3%;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
}

.frame {
  box-sizing: border-box;
  width: 250px;
  height: 300px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #20151b;
  color: white;
  text-align: center;
  transform: translateY(0%);
  opacity: 1;
  transition: opacity 0.3s ease;
}
.frameh4 {
  top: 0px;
  position: relative;
  color: #fff;
}
.frameh5 {
  position: relative;
  font-weight: lighter;
  top: 10px;
}
/* .image-section:hover .text-overlay {
    opacity: 0;
} */

/* How it works  */

.Works-section {
  padding: 10px;
  min-height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.slide-icon {
  position: absolute;
  top: 20%;
  width: 1%;
  right: 30%;
}

.HowItWorks {
  position: absolute;
  top: 15%;
  left: 5%;
  font-size: 14px;
  text-transform: uppercase;
}

.ItWorks {
  position: absolute;
  top: 18%;
  left: 5%;
  font-size: 44px;
}
.ItWorks span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 10%;
  font-weight: bold;
}

.curved-rectangles {
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
}

.curved-rectangle {
  margin: 70px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  text-align: center;
  padding: 0px;
  max-height: 180px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.curved-rectangle img {
  position: relative;
  max-width: 100%;
  top: -20px;
}

.curved-rectangle h4 {
  color: #fff;
  font-size: 16px;
  margin-top: -80px;
}

/* WebsiteStats css */
.website-stats-container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
}

.stats-rectangle {
  background: #000;
  width: 700px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: space-between;
  color: #fff;
  padding: 10px; /* Add some padding for spacing */
  position: relative;
}

.stat-circle {
  background: #fff;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50%;
  color: #000;
}

/* Create gaps between the circles */
.stat-circle:nth-child(1) {
  left: 12%;
}

.stat-circle:nth-child(2) {
  left: 42%;
}

.stat-circle:nth-child(3) {
  left: 72%;
}

.circle-content {
  text-align: center;
  font-size: 20px;
}

.content-below {
  font-size: 13px;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: -40px;
}

/* cutomer reviews */
.customer-review-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  z-index: 2;
}
.customer-review-page::before {
  content: "";
  background: url("./images/net.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 60%;
  top: 25%;
  left: 0;
  z-index: 1;
}

.clients {
  position: absolute;
  top: 5%;
  font-size: 14px;
  text-transform: uppercase;
}

.what {
  position: absolute;
  top: 7%;
  font-size: 44px;
}
.what span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 90px;
  font-weight: bold;
}

.customer-review-container {
  background: #fff;
  margin-top: 5%;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 50%;
  display: flex;
  position: relative;
  z-index: 2;
}

.review-left {
  width: 50%;
  background: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.quotation-mark {
  position: absolute;
  top: -60px;
  left: -45px;
  width: 40%;
  color: #fff;
}

.review-author {
  position: absolute;
  bottom: 35px;
  right: 20px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.review-position {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.review-right {
  width: 50%;
  overflow: hidden;
}

.customer-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigation-buttons {
  position: absolute;
  bottom: -10px;
  right: -27px;
  display: flex;
  gap: 0;
}

.nav-container {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}

.nav-button {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: grey;
  padding: 6px 6px;
  border-right: 1px solid #ccc;
}

.nav-button:last-child {
  border-right: none;
}

.nav-button:hover {
  background: #007bff;
  color: #fff;
}

/* Experts */
.experts-section {
  padding: 20px;
  max-height: 120vh;
  text-align: center;
  position: relative;
}
.nav-arrow {
  position: absolute;
  top: 15%;
  width: 1%;
  right: 30%;
}

.OurExperts {
  position: absolute;
  top: 13%;
  left: 5%;
  font-size: 14px;
  text-transform: uppercase;
}
.OExperts {
  position: absolute;
  top: 16%;
  left: 5%;
  font-size: 44px;
}
.OExperts span {
  position: absolute;
  display: contents;
  color: #fff;
  font-size: 44px;
  left: 10%;
  font-weight: bold;
}
.expert-container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-top: 15%;
  margin-left: 10%;
}

.expert-frame {
  box-sizing: border-box;
  width: 26.7%;
  height: 30%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.expert-frame.full-width {
  position: relative;
  left: 35%;
}

.expert-frame:hover {
  transform: scale(1.05);
}

.background-image {
  width: 60%;
  height: 270px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.expert-image img {
  width: 50%;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  top: -240px;
  left: -60px;
}
.expert-frame.full-width .expert-image {
  position: absolute;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.expert-frame.full-width .expert-image img {
  position: relative;
  left: 0.5px;
}

.expert-frame.full-width .expert-details {
  position: relative;
  padding-top: 20px;
  left: -20%;
  bottom: -10%;
}

.expert-social-icons {
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 60.3%;
  top: 235px;
  left: 0px;
  height: 35px;
  padding: 5px;
}

.expert-social-icons img {
  width: 13%;
  height: 80%;
  position: relative;
  top: 5px;
}

.expert-details {
  position: absolute;
  top: 58%;
  left: 18%;
}
.expert-name {
  font-size: 16px;
}
.expert-position {
  font-size: 12px;
}

.flexed-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* @media (max-width: 768px) {
    .frame-container {
      flex-direction: column;
      align-items: center;
    }
  
    .frame {
      margin-bottom: 20px;
    }
  
    .expert-image img {
      top: 0;
    }
  } */

/* footer.css */
/* Footer Styles */
/* Footer Styles */
.footer {
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.top-image {
  position: relative;
  bottom: 130px;
}

.bottom-image {
  position: absolute;
  bottom: -100px;
  z-index: 1;
}

.link {
  color: white;
  text-decoration: none;
}
.copyright {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -100px;
}

.footer-mask {
  display: inline-block;
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  width: 950px;
  border-radius: 50px;
  z-index: 2;
  top: 90px;
}
.footer-upper {
  position: relative;
  text-align: center;
  padding: 20px 0;
  z-index: 1;
}

.footer-text {
  color: #fff;
}

.footer-text a {
  font-size: 18px;
  color: #2d87f0;
  text-decoration: none;
}

.footer-lower {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  z-index: 1;
}

.footer-column {
  flex: 1;
  padding: 0 20px;
}
.footer-column a {
  color: #2d87f0;
}

ul {
  list-style: none;
  padding: 10px;
}

.email-input {
  display: flex;
  flex-direction: column;
}

.email-input input {
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin: 5px 0;
}

.email-input button {
  background: #2d87f0;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.social-icons {
  position: absolute;
  display: flex;
  margin-top: 10px;
  left: 700px;
  top: 130px;
  width: 150px;
  /* background: rgba(0, 0, 0, 0.3);*/
}

.social-icons a {
  margin-right: 20px;
  text-decoration: none;
  font-size: small;
}
.social-icons a img {
  width: 44px;
  height: 30px;
}

.footer-column li,
p {
  font-size: 16px;
  font-weight: lighter;
}
.footer-mask h3 {
  font-size: 20px;
}
.footer-mask h4 {
  font-size: 18px;
  color: #2d87f0;
}

.naamii-logo{
  width: 200px;
  margin-right: 100px;
  border-radius: 20px;
}

.diyo-logo{
  width: 534px;
  border-radius: 20px;

}

.solution-page {
  padding: 20px;
  min-height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}